import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import LazyLoad from "react-lazyload"

import { FAQ_ITEMS_PREFIX, faqItems } from "../../constants"

import Description from "../Description"
import Section from "../Section"
import { FeedbackFormLink } from "../FeedbackRequest/FeedbackFormLink"

import Expand from "../Expand"

const Faq = ({ styles, rules }) => {
  return (
    <Section fullWidth narrow extend={{ section: rules.section }}>
      <LazyLoad height={200}>
        <Description
          title={<FormattedMessage id={`faq.title`} />}
          extend={{ title: rules.title }}
          align="center"
        >
          {faqItems.map((id, index) => (
            <Expand
              key={id}
              order={index + 1}
              title={
                <FormattedMessage id={`${FAQ_ITEMS_PREFIX}${id}.question`} />
              }
            >
              <FormattedMessage
                id={`${FAQ_ITEMS_PREFIX}${id}.answer`}
                values={{
                  p: (...texts) => <p>{texts}</p>,
                  purple: text => (
                    <span key={text} className={styles.textHighlight}>
                      {text}
                    </span>
                  ),
                  feedbackFormLink: text => (
                    <FeedbackFormLink>{text}</FeedbackFormLink>
                  ),
                }}
              />
            </Expand>
          ))}
        </Description>
      </LazyLoad>
    </Section>
  )
}

Faq.propTypes = {
  rules: PropTypes.shape({
    section: PropTypes.func.isRequired,
    title: PropTypes.func.isRequired,
  }).isRequired,
}

export default Faq
