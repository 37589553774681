import React from "react"
import PropTypes from "prop-types"

import Icon from "../../Icon"

const Expander = ({ open, rules }) => (
  <Icon type="arrowTab" style={rules.expander} open={open} />
)

Expander.propTypes = {
  open: PropTypes.bool.isRequired,
  rules: PropTypes.shape({
    expander: PropTypes.func.isRequired,
  }).isRequired,
}

export default Expander
