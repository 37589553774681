import React from "react"
import { FormattedMessage } from "gatsby-plugin-react-intl"
/* import LazyLoad from "react-lazyload" */

import { useAppUrl } from "../../../hooks/useAppUrl"

import { Heading2 } from "../../Heading"
import Section from "../../Section"

import Button from "../../Button"
import Benefits from "../Benefits"

import { aboutSectionExtend } from "../About.styles"

import { strongTagReplacer } from "../../../utils/intl"

const About = ({ styles }) => {
  const registrationUrl = useAppUrl("registrace")

  return (
    <Section>
      <Section extend={aboutSectionExtend} narrow>
        <Heading2>
          <FormattedMessage id="about.title" />
        </Heading2>
        <FormattedMessage
          id="logopeds.about.text.p1"
          tagName="p"
          values={{ strong: strongTagReplacer }}
        />
        <FormattedMessage
          id="logopeds.about.text.p2"
          tagName="p"
          values={{ strong: strongTagReplacer }}
        />

        {/* <LazyLoad height={200}> */}

        <div className={styles.noteContent}>
          <FormattedMessage id="cta.register">
            {label => (
              <Button
                pink
                href={registrationUrl}
                target="_blank"
                type={Button.Types.LINK}
                rel="noreferrer"
              >
                {label}
              </Button>
            )}
          </FormattedMessage>
        </div>

        {/* </LazyLoad> */}
      </Section>

      {/* <LazyLoad height={200}> */}
      <Benefits
        benefits={[
          { id: "logoped.benefit1", icon: "begin" },
          { id: "logoped.benefit2", icon: "tool" },
          { id: "logoped.benefit3", icon: "statistics" },
        ]}
      />
      {/* </LazyLoad> */}
    </Section>
  )
}

About.propTypes = {}

export default About
