export const preview = () => ({})

export const caption = () => ({
  marginTop: "2.3rem",
  textAlign: "left",
})

export const title = ({ theme }) => ({
  marginBottom: "0.5rem",

  fontFamily: theme.font.family,
  fontSize: "1rem",
  fontWeight: theme.font.weightBold,

  color: "#3A3B45",
})

export const description = ({ theme }) => ({
  fontFamily: theme.font.family,
  fontSize: "0.9rem",
  fontStyle: "italic",
  lineHeight: "1.75",
  color: "#666666",
})
