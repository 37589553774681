import React from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"

import { useAppUrl } from "../hooks/useAppUrl"
import { donationUrl } from "../config"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import BasicIntro from "../components/BasicIntro"
import About from "../components/About/AboutLogopeds"
import Contact from "../components/Contact"
import Faq from "../components/Faq"
import CtaRow from "../components/CtaRow"
import FeedbackRequest from "../components/FeedbackRequest"
import AppPreviews from "../components/About/AppPreviews" // TODO - move outside About
import Citations from "../components/Citations"
import Section from "../components/Section"
import Button from "../components/Button"

const LogopedsPage = () => {
  const intl = useIntl()
  const registrationUrl = useAppUrl("registrace")

  return (
    <Layout
      headerIntro={
        <BasicIntro intlId="logopeds">
          <Button
            pink
            // extend={buttonExtend}
            href={donationUrl}
            target="_blank"
            type={Button.Types.LINK}
          >
            <FormattedMessage id="donation.button.support" />
          </Button>
        </BasicIntro>
      }
    >
      <Seo title={intl.formatMessage({ id: "page.logopeds.title" })} />

      <About />
      <AppPreviews />

      <Section>
        <Citations
          citations={[
            {
              id: "zsolt",
              color: "green",
              titleBefore: true,
              titleAfter: true,
            },
            { id: "zuzana" },
          ]}
        />
      </Section>

      <Faq />

      <CtaRow
        titleId="logopeds.cta.title"
        textId="logopeds.cta.text"
        buttonLabelId="cta.register"
        link={{ href: registrationUrl, target: "_blank", rel: "noreferrer" }}
      />
      <FeedbackRequest />

      <Contact />
    </Layout>
  )
}
export default LogopedsPage
