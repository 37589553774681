export const container = ({ theme }) => ({
  maxWidth: 730,
  margin: "12px auto",

  backgroundColor: theme.colors.white,
  textAlign: "left",
})

export const header = ({ theme: { colors } }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  padding: "16px 24px",

  cursor: "pointer",
  backgroundColor: colors.white,

  borderWidth: "2px 0",
  borderColor: colors.blueLight4,
  borderStyle: "solid",

  smMin: {
    padding: "20px 32px",
  },
})

export const title = () => ({
  display: "inline-flex",
  flex: "1 1 0",

  paddingRight: "0.5rem",
  marginBottom: 0,
  textAlign: "left",
})

export const body = ({ open, theme }) => ({
  maxHeight: open ? 1000 : 0, // https://stackoverflow.com/questions/3508605/how-can-i-transition-height-0-to-height-auto-using-css
  padding: `${open ? 18 : 0}px 26px`,

  transition: "all 0.2s",

  overflow: "hidden",
  boxSizing: "border-box",

  color: theme.colors.grey2,

  smMin: {
    padding: `${open ? 36 : 0}px 53px`,
  },
})
