export const expander = ({ open, theme: { colors } }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  width: 40,

  borderRadius: 20,
  backgroundColor: colors.blueLight3,

  transform: `rotate(${open ? 0 : 180}deg)`,
  transition: "transform 0.2s",

  "& svg": {
    height: 9,
    color: open ? colors.pink : colors.purpleDark2,
  },
})
