import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import { graphql, useStaticQuery } from "gatsby"
import get from "lodash/get"
import LazyLoad from "react-lazyload"

import Section from "../../Section"
import Description from "../../Description"
import Carousel from "../../Carousel"
import Preview from "./Preview"

const AppPreviews = ({ rules }) => {
  const {
    all: { images },
  } = useStaticQuery(graphql`{
  all: allFile(filter: {relativeDirectory: {eq: "graphic/app-previews"}}) {
    images: edges {
      img: node {
        name
        childImageSharp {
          gatsbyImageData(width: 510, placeholder: TRACED_SVG, layout: CONSTRAINED)
        }
      }
    }
  }
}
`)

  return (
    <Section fullWidth extend={{ section: rules.section }}>
      <LazyLoad height={200}>
        <Description
          title={<FormattedMessage id={`previews.title`} />}
          extend={{ title: rules.title }}
          align="center"
        >
          <Carousel>
            {["preview1", "preview2", "preview3", "preview4", "preview5"].map(
              (name, i) => (
                <Preview
                  key={name}
                  order={i + 1}
                  name={name}
                  image={get(
                    images.find(({ img }) => img.name === name),
                    "img.childImageSharp"
                  )}
                />
              )
            )}
          </Carousel>
        </Description>
      </LazyLoad>
    </Section>
  )
}
AppPreviews.propTypes = {
  rules: PropTypes.shape({
    section: PropTypes.func.isRequired,
    title: PropTypes.func.isRequired,
  }).isRequired,
}

export default AppPreviews
