import arrowThin from "../../images/css/arrow-thin.svg"

const ARROW_WIDTH = 40
const ARROW_HEIGHT = 28
const ARROW_MARGIN = 5

export const carousel = ({ theme }) => ({
  mdMin: {
    marginLeft: ARROW_WIDTH + ARROW_MARGIN,
    marginRight: ARROW_WIDTH + ARROW_MARGIN,
  },

  "& .slick-arrow": {
    width: ARROW_WIDTH,
    height: ARROW_HEIGHT,
    top: "35%",
  },

  "& .slick-next": {
    right: -1 * (ARROW_WIDTH + ARROW_MARGIN),
    ":before": {
      content: `url(${arrowThin})`,
    },
  },

  "& .slick-prev": {
    left: -1 * (ARROW_WIDTH + ARROW_MARGIN),
    transform: `rotate(180deg) translate(0, ${ARROW_HEIGHT / 2}px)`,
    ":before": {
      content: `url(${arrowThin})`,
    },
  },

  "& .slick-dots li": {
    "& button:before": {
      color: "#DFDEE3",
      opacity: 1,
      fontSize: 10,
    },
    "&.slick-active button:before": {
      color: theme.colors.purpleDark2,
    },
  },

  "& .slick-slide": {
    paddingLeft: 20,
    paddingRight: 20,
  },
})
