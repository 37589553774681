export const section = () => ({
  paddingTop: "2.2rem",
  paddingBottom: "5rem",
})

export const title = () => ({
  margin: "2.84rem 0",
})

export const textHighlight = ({ theme }) => ({
  color: theme.colors.purpleDark2,
})
