export const section = () => ({
  paddingTop: "4.8rem",
  paddingBottom: "4.8rem",
})

export const title = ({ theme }) => ({
  marginTop: 0,
  marginBottom: "2.3rem",
  textAlign: "center",
})

export const text = ({ theme }) => ({
  marginBottom: "2.3rem",
})

export const signature = ({ theme }) => ({
  marginBottom: 0,
  textAlign: "center",
})

export const link = ({ theme }) => ({
  color: theme.colors.pink,
  fontWeight: "normal",
  textDecoration: "underline",
})
