import React from "react"

import Link from "../Link"

import { useIntl } from "gatsby-plugin-react-intl"

const feedbackFormId = {
  cs: "1FAIpQLSeJdYijakI7CVdcOfA6fIl_1VhaDZL8WgQARe10NhS12qMBXQ",
  sk: "1FAIpQLSeNZAYw8sGh1NYBMs3yVgobcJbbZMZj9UO5HmikwJypEKWV0A",
}

const link = ({ theme }) => ({
  color: theme.colors.pink,
  fontWeight: "normal",
  textDecoration: "underline",
})

export const FeedbackFormLink = ({ children }) => {
  const { locale } = useIntl()

  const formId = feedbackFormId[locale]

  const feedbackFormUrl = `https://docs.google.com/forms/d/e/${formId}/viewform?usp=sharing`

  return (
    <Link href={feedbackFormUrl} type={Link.Types.EXTERNAL} customStyle={link}>
      {children}
    </Link>
  )
}
