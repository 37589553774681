import React from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
  ],
}

const Carousel = ({ children, styles }) => (
  <Slider {...{ ...settings, className: styles.carousel }}>{children}</Slider>
)

Carousel.propTypes = {
  styles: PropTypes.shape({
    carousel: PropTypes.string.isRequired,
  }).isRequired,
}

export default Carousel
