import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import { GatsbyImage } from "gatsby-plugin-image"

const Preview = ({ image, name, order, styles }) => (
  <figure className={styles.preview}>
    {/* <img src="" width="441" height="339" /> */}
    {image ? <GatsbyImage image={image.gatsbyImageData} alt="" /> : null}
    <figcaption className={styles.caption}>
      <FormattedMessage id={`previews.${name}.title`}>
        {title => (
          <h4 className={styles.title}>
            {order}. {title}
          </h4>
        )}
      </FormattedMessage>
      <FormattedMessage id={`previews.${name}.text`}>
        {text => <span className={styles.description}>{text}</span>}
      </FormattedMessage>
    </figcaption>
  </figure>
)

Preview.propTypes = {
  image: PropTypes.shape({
    fluid: PropTypes.object,
  }),
  name: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  styles: PropTypes.shape({
    preview: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
}

Preview.defaultProps = {
  image: null,
}

export default Preview
