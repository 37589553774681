import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import Obfuscate from "react-obfuscate"

import { Heading3 } from "../Heading"
import Section from "../Section"
import { FeedbackFormLink } from "./FeedbackFormLink"

import { strongTagReplacer } from "../../utils/intl"

const FeedbackRequest = ({ styles, rules }) => {
  return (
    <Section fullWidth narrow extend={{ section: rules.section }}>
      <FormattedMessage id="feedback.title">
        {title => <Heading3 customStyle={rules.title}>{title}</Heading3>}
      </FormattedMessage>

      <FormattedMessage
        id="feedback.text"
        values={{
          strong: strongTagReplacer,
          a1: text => <FeedbackFormLink>{text}</FeedbackFormLink>,
          a2: ([email]) => <Obfuscate email={email} />,
        }}
      >
        {texts => (
          <p className={styles.text}>
            {// Temporary hack, until rich text formatting on server will be supported
            Array.isArray(texts)
              ? texts.map((text, i) => (
                  <React.Fragment key={i}>{text}</React.Fragment>
                ))
              : texts}
          </p>
        )}
      </FormattedMessage>
      <FormattedMessage id="feedback.signature">
        {text => <p className={styles.signature}>{text}</p>}
      </FormattedMessage>
    </Section>
  )
}

FeedbackRequest.propTypes = {
  styles: PropTypes.shape({}).isRequired,
  rules: PropTypes.shape({
    title: PropTypes.func,
  }).isRequired,
}

FeedbackRequest.defaultProps = {}

export default FeedbackRequest
