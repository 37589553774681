import React from "react"
import PropTypes from "prop-types"
import { useFela } from "react-fela"

import { Heading4 } from "../Heading"
import Expander from "./Expander"

const CLOSED = false

const Expand = ({ title, order, styles, children, rules }) => {
  const [open, toggleOpen] = React.useState(CLOSED)
  const { css } = useFela({ open })

  return (
    <div className={styles.container}>
      <div
        className={css(rules.header)}
        onClick={() => toggleOpen(!open)}
        onKeyPress={e => {
          if (e.key === "Enter") {
            toggleOpen(!open)
          }
        }}
        role="button"
        tabIndex="0"
      >
        <Heading4 customStyle={rules.title}>
          <span>{order}.&nbsp;</span> <span>{title}</span>
        </Heading4>
        <Expander open={open} />
      </div>
      <div className={css(rules.body)}>{children}</div>
    </div>
  )
}

Expand.propTypes = {
  title: PropTypes.node.isRequired,
  order: PropTypes.number.isRequired,
  styles: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
}

export default Expand
